import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Vue from 'vue'
// import {AVPlugin } from 'vue-audio-visual'
import VueTour from 'v3-tour'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {plugin, defaultConfig} from '@formkit/vue'
// import '@formkit/themes/genesis'
import * as Sentry from "@sentry/vue";

import '@formkit/themes/genesis';
/*
const FormKitConfig = defaultConfig({
  theme: 'genesis',
});
*/


// Vue.use(AudioVisual)

// require('v3-tour/dist/vue-tour.css')

import '../public/expat.css';

const app = createApp(App);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app
    .use(store)
    .use(router)
    // .use(AVPlugin)
    .use(VueTour)
    .use(VueAxios, axios)
    .use(plugin, defaultConfig({
            theme: 'genesis' // will load from CDN and inject into document head
        })
    )
    // .use(plugin, FormKitConfig)
    .mount('#app');

