import {createStore} from 'vuex';
import * as Sentry from "@sentry/vue";
import axios from "axios";
// import {commit} from "lodash/seq";

const default_progression = {
    partA: {
        partA01: {
            done: true
        },
        partA02: {
            done: false
        },
        partA03: {
            done: false
        },
        partA04: {
            done: false
        },
        partA05: {
            done: false
        },
        partAR1: {
            done: false
        },
        partA06: {
            done: false
        },
        partA07: {
            done: false
        },
        partA08: {
            done: false
        },
        partAR2: {
            done: false
        },
        partA09: {
            done: false
        },
        partA10: {
            done: false
        },
        partA11: {
            done: false
        },
        partA12: {
            done: false
        },
        partAR3: {
            done: false
        },
        partAR4: {
            done: false
        }
    },
    partB: {
        partB01: {
            done: false
        },
        partB02: {
            done: false
        },
        partB03: {
            done: false
        }
    },
    partC: {
        partC01: {
            score: 0
        }
    }
};


function _err(err, data) {
    let label = err instanceof Error ? err.message : err;
    console.error('Erreur ' + label, data);
    Sentry.withScope(scope => {
        scope.setExtra('data', data);
        Sentry.captureException(err);
    });
}

export default createStore({
    state: {parts: {}, token: '', current: ''},
    getters: {
        getSubpart: (state) => (path) => {
            let [part, subpart, key] = path.split('.');
            console.log('getSubpart: ' + path + ' -> ', state.parts[part][subpart][key]);
            return state.parts[part][subpart][key] || false;
        }
    },
    mutations: {
        SET_CODE(state, code) {
            state.code = code;
            console.log('Store code is now ' + code);
        },
        SET_TOKEN(state, token) {
            state.token = token;
            console.log('Store token is now ' + token);
        },
        SET_CURRENT(state, current) {
            state.current = current;
            console.log('Store current is now ' + current);
        },
        SET_PARTS(state, parts) {
            state.parts = parts;
        },
        SAVE_PROGRESSION(state, payload) {
            console.log('(store) Saving progression', state.parts);

            if (payload.path) {

                let [part, subPath, key] = payload.path.split('.');

                if (part && !state.parts[part]) {
                    state.parts[part] = {};
                }
                if (part && subPath && !state.parts[part][subPath]) {
                    state.parts[part][subPath] = {};
                }

                if (payload.keywords) {
                    const keywords = payload.keywords;
                    subPath = part;

                    for (const cat_idx in keywords) {
                        let cat = keywords[cat_idx];
                        // localStorage.setItem('expat.partA.' + path + '.' + cat.catref, JSON.stringify(cat.catkeywords));
                        if (!state.parts.partA[subPath].keywords) {
                            state.parts.partA[subPath].keywords = {};
                        }
                        state.parts.partA[subPath].keywords[cat.catref] = cat.catkeywords;
                    }
                }

                if (part && subPath && key && payload.data) {
                    // localStorage.setItem('expat.' + path, JSON.stringify(payload.data));
                    state.parts[part][subPath][key] = payload.data;
                }
            }
            if (payload.current) {
                state.current = payload.current;
            }

            /*localStorage.setItem('expat.partB.action_source', JSON.stringify(this.action_source));
            localStorage.setItem('expat.partB.plan_target_items', JSON.stringify(this.plan_target_items));*/

            // localStorage.setItem('expat.progression', JSON.stringify(state.parts));
        },
        CLEAR_PROGRESSION(state) {
            console.log('(store) Clearing ' + localStorage.length);
            state.parts = default_progression;
            // this.commit('SAVE_PROGRESSION');
            for (let i = localStorage.length; i > 0; i--) {
                let k = localStorage.key(i - 1);
                if (k.startsWith('expat.')) {
                    // console.log('Deleting ' + k);
                    localStorage.removeItem(k);
                } else {
                    // console.log('Not deleting ' + k);
                }
            }
            localStorage.removeItem('expat.progression');
            // this.commit('SEND_PROGRESSION');
            // location.reload();
        },
        SEND_PROGRESSION(state) {
            console.log('(store) Sending ', state);
            const code = localStorage.getItem('expat.code');
            axios.post(import.meta.env.VITE_BACKEND, {state: JSON.stringify(state), code: code, current: state.current},
                {headers: {'X-Requested-With': 'XMLHttpRequest', 'X-Requested-Type': 'json', 'X-Remote-Call': 'Save'}})
                .then(function (response) {
                    console.log('SENT', response);
                })
                .catch(function (error) {
                    _err(error);
                });
        }
    },
    actions: {
        loadProgression(context) {
            // this.commit('LOAD_PROGRESSION');
            console.log('(store) Loading ');
            try {
                const code = localStorage.getItem('expat.code');
                const token = localStorage.getItem('expat.token');
                if (code && token) {
                    return axios.post(import.meta.env.VITE_BACKEND,
                        {code, token},
                        {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                                'X-Requested-Type': 'json',
                                'X-Remote-Call': 'Load'
                            }
                        })
                        .then(function (response) {
                            console.log('LOADED', response);
                            if (response.data.status === 'OK') {
                                const data_parts = response.data.state.parts;
                                // console.log('data', response.data);
                                context.commit('SET_PARTS', data_parts || default_progression);
                                context.commit('SET_CURRENT', response.data.current || '');
                            } else {
                                _err('Error loading', response.data);
                            }
                        })
                        .catch(function (error) {
                            _err(error);
                        });
                } else {
                    context.commit('SET_PARTS', default_progression);
                }
            } catch (err) {
                // console.log('ERR LOADING', err);
                _err(err);
            }/* finally {
                console.log('DONE LOADING');
            }*/
        },
        saveProgression(context, payload) {
            context.commit('SAVE_PROGRESSION', payload);
        },
        clearProgression() {
            this.commit('CLEAR_PROGRESSION');
        },
        sendProgression() {
            this.commit('SEND_PROGRESSION');
        },
        async checkToken(context, home) {
            // console.log(context, home);
            const token = context.state.token;
            console.log('Checking token: ' + token + ' against ' + import.meta.env.VITE_BACKEND);
            try {
                const {data} = await home.$http.post(
                    import.meta.env.VITE_BACKEND,
                    {token: token},
                    {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'X-Requested-Type': 'json',
                            'X-Remote-Call': 'CheckToken'
                        }
                    }
                );
                home.loading = false;
                if (data.status === 'OK') {
                    // self.startGame();
                    home.token = data.token ?? '';
                    home.clientName = data.clientName ?? '';
                    home.urlLabel = data.label ?? '';
                } else {
                    home.message = data.message ?? 'Erreur de vérification du jeton';
                    home.err = data.err;
                    _err('CheckToken', data);
                }
            } catch (e) {
                home.message = 'Erreur de connexion au serveur';
                home.err = '?';
                console.error('Exception checkToken', e);
                throw new Error(home.message + ': ' + e.message);
            }
        },
        async startGame(context, home) {
            console.log('Starting game with ' + import.meta.env.VITE_BACKEND);
            try {
                home.err = '';

                if (home.code) {
                    const {data} = await home.$http.post(
                        import.meta.env.VITE_BACKEND,
                        {code: home.code, token: home.token},
                        {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                                'X-Requested-Type': 'json',
                                'X-Remote-Call': 'CheckCode'
                            }
                        }
                    );
                    console.log('OK', data);
                    if (data.status === 'OK') {
                        localStorage.setItem('expat.code', data.code);
                        localStorage.setItem('expat.token', data.token);
                        home.$store.commit('SET_CODE', data.code);

                        home.$store.dispatch('loadProgression').then(() => {
                            const url = home.$store.state.current;
                            if (url) {
                                console.log('Starting at ' + url);
                                home.$router.push(url);
                            } else {
                                console.log('Starting with default location');
                            }
                        });
                    } else {
                        home.err = data.message || true;
                        home.loading = false;
                    }
                    // home.loading = false;
                } else {
                    const {data} = await home.$http.post(
                        import.meta.env.VITE_BACKEND,
                        {token: home.token},
                        {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                                'X-Requested-Type': 'json',
                                'X-Remote-Call': 'GetCode'
                            }
                        }
                    );
                    console.log('OK', data);
                    if ((data.status === 'OK') && (data.code)) {
                        home.code = data.code;

                        home.url = '/about/partRA01';
                        // alert('Votre nouveau code est ' + home.code);
                        localStorage.setItem('expat.code', home.code);
                        home.$store.commit('SET_CODE', home.code);
                        home.$store.dispatch('saveProgression', {current: home.url});
                        home.$store.dispatch('sendProgression');
                    } else {
                        alert('Impossible d\'obtenir un nouveau code...');
                    }
                    home.loading = false;
                }

            } catch (err) {
                _err(err);
                alert('Une erreur s\'est produite lors de la connexion au serveur...');
            } finally {
                console.log('DONE');
            }
        }
    },
    modules: {},
})
